/// Format for CSS classes created with Motion UI.
/// @type Map
/// @prop {Boolean} append [true] - Defines if selectors are chained to the selector (`.class.enter`), or appended as a new class (`.class-enter`).
/// @prop {String} prefix ['mui-'] - Prefix to add before the state of a class. Enter an empty string to use no prefix.
/// @prop {String} prefix ['-active'] - Suffix to add to the active state class.
$motion-ui-classes: (
  chain: true,
  prefix: 'mui-',
  active: '-active',
) !default;

/// State names to reference when writing motion classes. To use multiple class names for one state, enter a list of strings instead of one string.
/// @type Map
$motion-ui-states: (
  in: 'enter',
  out: 'leave',
) !default;

/// Default speed that transitions and animations play at, along with values for modifier classes to change the speed.
/// @type Map
$motion-ui-speeds: (
  default: 500ms,
  slow: 750ms,
  fast: 250ms,
) !default;

/// Default delay to add before motion, along with values for modifier classes to change the delay.
/// @type Map
$motion-ui-delays: (
  default: 0,
  short: 300ms,
  long: 700ms,
) !default;

/// Default easing for transitions and animations, along with values for modifier classes to change the easing.
/// @type Map
$motion-ui-easings: (
  default: linear,
  linear: linear,
  ease: ease,
  ease-in: ease-in,
  ease-out: ease-out,
  ease-in-out: ease-in-out,
  bounce-in: cubic-bezier(0.485, 0.155, 0.24, 1.245),
  bounce-out: cubic-bezier(0.485, 0.155, 0.515, 0.845),
  bounce-in-out: cubic-bezier(0.76, -0.245, 0.24, 1.245),
) !default;

/// Miscellaneous settings related to Motion UI.
/// @type Map
/// @prop {Boolean} slide-and-fade [false] - Defines if slide motions should also fade in/out.
/// @prop {Boolean} slide-and-fade [true] - Defines if hinge motions should also fade in/out.
/// @prop {Boolean} slide-and-fade [true] - Defines if scale motions should also fade in/out.
/// @prop {Boolean} slide-and-fade [true] - Defines if spin motions should also fade in/out.
$motion-ui-settings: (
  slide-and-fade: false,
  hinge-and-fade: true,
  scale-and-fade: true,
  spin-and-fade: true,
  activate-queue-class: 'is-animating',
) !default;
