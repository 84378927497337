#topbar {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  z-index: 4;
  pointer-events: none;
  display: none;

  &.topbar-closed {
    opacity: 0;
    visibility: hidden;
    transition: opacity 500ms, visibility 0s linear 500ms;

    .menu {
      opacity: 0.4;
      transform: translateY(-50%) rotateX(35deg);
      transition: transform 500ms, opacity 500ms; } }

  &.topbar-open {
    opacity: 1;
    visibility: visible;
    transition: opacity 500ms;
    pointer-events: auto;

    .menu {
      opacity: 1;
      transform: translateY(20%) rotateX(0deg);
      transition: transform 500ms, opacity 500ms; } }

  .menu-links {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($primary-color, 0.8); }

  .nav {
    position: relative;
    top: 30px;
    height: 60%;
    font-size: 5vw;
    perspective: 1200px;
    margin: 0px;
    text-align: center; }

  .menu {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    height: 100%;
    position: relative;

    li {
      display: block;
      padding: 10px 0; }

    a {
      text-decoration: none;
      text-transform: lowercase;
      color: white;
      transition: color 200ms; } } }

// Hamburger--------------------------------------------------------------------
#hamburger {
  display: block;
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 201;
  width: 51px;
  height: 51px;
  padding: 13px 11px;
  margin: 0;
  cursor: pointer;
  border: 2px solid $white;
  pointer-events: auto;

  span {
    display: block;
    position: absolute;
    height: 3px;
    left: 11px;
    right: 11px;
    background: $white;
    border-radius: 0px;
    opacity: 1;
    transform: rotate(0deg);
    transition: transform 150ms ease-out 0ms, top 100ms linear 150ms;
    transform-origin: center;

    &:nth-child(1) {
      top: 13px; }

    &:nth-child(2) {
      top: 9px + 13px; }

    &:nth-child(3) {
      top: 18px + 13px; } }

  &.open span {
    transition: transform 150ms ease-out 100ms, top 100ms linear; }

  &.open span:nth-child(1) {
    transform: rotate(45deg);
    top: 9px + 13px; }

  &.open span:nth-child(2) {
    transform: rotate(45deg); }

  &.open span:nth-child(3) {
    transform: rotate(-45deg);
    top: 9px + 13px; } }
