//Topbar------------------------------------------------------------------------

//Top section-------------------------------------------------------------------
.logo {
  display: inline-block;
  padding: 3em 5em;
  background-color: rgba($primary-color, 0.85); }

.inicio {
  display: block;
  padding: 2em 1em;
  background: linear-gradient(rgba($black, 0.5), rgba($black, 0.5)), url('../img/cnazare.jpg');
  background-size: cover;
  background-position: 50% 50%;
  text-align: center; }

.slogan {
  font-weight: 300;
  max-width: 800px;
  margin: 1em auto;
  color: $white; }

section {
  display: block;
  padding: 6em 1em;
  position: relative;
  text-align: center;

  h2 {
    color: $primary-color;
    text-transform: uppercase; } }

section.inverted {
  background-color: $primary-color;
  color: $white;

  h2 {
    color: $white; } }

.second {
  h2 {
    margin: 1.5em 0;
    text-transform: uppercase;
    color: $white; } }

.servicos {
  h2 {
    margin-bottom: 2.5em;
    text-transform: uppercase;
    color: $primary-color; }

  .icon-wrapper {
    display: block;
    position: relative;
    width: 150px;
    height: 150px;
    margin: auto;
    background-color: $primary-color;
    border-radius: 50%;

    img {
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); } }

  h5 {
    margin: 1em;
    padding-bottom: 1em;
    text-transform: uppercase;
    color: $primary-color; } }

.poupar {
  background-color: $light-gray;

  h2 {
    color: $primary-color;
    margin: 1.5em 0;
    text-transform: uppercase; } }

footer {
  display: block;
  position: relative;

  .mapa {
    position: relative;
    height: 300px; }

  .info {
    display: block;
    position: relative;
    padding: 3em;
    background-color: $primary-color;
    text-align: center;

    a {
      color: $white;
      font-weight: 400; }

    span {
      display: block; } }

  .info-icon {
    display: inline-block;
    position: relative;
    margin: 0 0.25em;
    width: 40px;
    height: 40px;
    background-color: $white;
    border-radius: 50%;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); } }

  .lower {
    display: block;
    position: relative;
    padding: 0.4em 1em;
    background-color: $primary-color;

    .di {
      width: 126px;
      margin: auto;
      float: left;

      span {
        display: block;
        float: right;
        font-size: 14px;
        color: $white;
        margin-bottom: 5px; }

      img {
        display: block;
        float: right; } }

    img {
      float: left; }

    .info-icon {
      float: right; } } }

//Página poupar energia---------------------------------------------------------
.lamp {
  animation: luz 3s infinite; }

@keyframes luz {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.6; }
  100% {
    opacity: 1; } }

.energia-intro {
  h2 {
    margin: 1em 0 0 0; }
  h4 {
    color: $primary-color;
    font-weight: 400; } }

.projecto {
  padding: 9em 1em; }

.objectivos-vantagens {
  div {
    background-position: 50% 50%;
    background-size: 220px;
    background-repeat: no-repeat; }

  ul {
    list-style: none;
    margin: 0; }

  h2 {
    text-align: center; } }

.objectivos {
  margin: 3.5em 0;
  text-align: left;

  ul {
    li:before {
      content: url('../img/icons/circulo.svg');
      display: inline-block;
      vertical-align: bottom;
      margin: 0 7px 2px 0; } } }

.vantagens {
  margin: 3.5em 0;
  text-align: right;

  ul {
    li:after {
      content: url('../img/icons/circulo.svg');
      display: inline-block;
      vertical-align: bottom;
      margin: 0 0 2px 7px; } }

  .medium-3 {
    float: right !important; } }

.actividades {
  padding: 6em 0em;
  background-color: $light-gray;

  .selector {
    display: block;
    position: relative;
    margin-bottom: 2em;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 4px;
      background-color: $primary-color;
      top: 50%;
      margin-top: -4px;
      left: 0; } }

  .actv-select {
    position: relative;
    display: inline-block;
    margin: 5px;
    width: 50px;
    height: 50px;
    border: 4px solid $primary-color;
    border-radius: 50%;
    background-color: $light-gray;
    cursor: pointer;
    transition: all 400ms ease-out;

    &.active {
      border: 4px solid darken($primary-color, 25);

      h3 {
        color: darken($primary-color, 25); } }

    &:hover {
      background-color: $primary-color;

      h3 {
        color: $light-gray; } }

    h3 {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $primary-color; } }

  .content {
    display: block;
    position: relative; }

  .item {
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 200ms linear 0ms;

    ul {
      text-align: left; }

    &.active {
      visibility: visible;
      opacity: 1;
      transition: all 200ms linear 200ms; } } }

.parceiros {
  h2 {
    margin: 1em 0 3em 0; }

  .flex {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap; }

  .item {
    width: 175px; }

  h5 {
    margin: 5em 0 1.5em 0;
    text-transform: uppercase;
    color: $secondary-color; } }

.reveal {
  background-color: $black;
  border: 0px none; }
