.inicio {
  padding: 8em 1em; }

.poupar {
  text-align: left; }

.objectivos-vantagens {
  div {
    background-size: contain; } }

.objectivos {
  h2 {
    text-align: left; } }

.vantagens {
  h2 {
    text-align: right; } }

footer {
  .mapa {
    height: 500px; }

  .info {
    display: block;
    position: absolute;
    top: 50%;
    right: 5vw;
    transform: translateY(-50%);
    background-color: rgba($primary-color, 0.75);
    z-index: 99; } }

//Página poupar energia---------------------------------------------------------
.energia-intro {
  text-align: center; }
